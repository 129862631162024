.main-button {
  display: flex;
  background-color: #2c6aa5;
  border-radius: 0.9em;
  padding: 0.8em 1.2em 0.8em 1em;
  transition: all ease-in-out 0.2s;
  font-size: 16px;
  width: 100%;
  height: auto;
  justify-content: left;
  text-wrap: nowrap;
}

.main-button .main-button-span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.main-button:hover {
  background-color: #0071e2;
}
.main-button .main-button-span .main-button-info {
  margin: 0;
  padding-left: 12px;
}
