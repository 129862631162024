.rules {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 120px;
    width: 130px;
    height: 130px;
    text-decoration: none;
  
}
.rules :hover{
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}
.rules{
    overflow:hidden;
}