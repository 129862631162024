html {
  scroll-behavior: smooth;
}
.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0% 50%;
  background-image: url("img/map.png");
}

.text-justify {
  text-align: justify;
}

.Collapsible {
  margin-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #b8b8b8;
  background: #b8b8b8;
}

.Collapsible__trigger {
  padding: 15px 15px 15px 15px;
  font-size: 20px;
}

.Collapsible__contentInner {
  border: 2px solid;
  padding: 14px 10px 10px 10px;
}

.Collapsible__contentInner > li {
  padding: 30px 30px 10px 30px;
}

.bgs_collapsible {
  background: #b8b8b8;
  background-color: rgb(187, 187, 187);
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
}

.btn-accesibility {
  position: fixed;
  bottom: 50%;
  right: 0px;
  margin: 20px;
  margin-right: 5px;
  z-index: 1;
}
.btn-start {
  position: fixed;
  bottom: 0px;
  left: 0px;
  margin: 20px;
  margin-bottom: 30px;
  z-index: 1;
}
.btn-chat {
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin: 20px;
  z-index: 1;
}

.btn-navpqrs {
  position: fixed;
  top: 15%;
  left: 0px;
  margin: 20px;
  padding: 0px;
  margin-left: 5px;
  z-index: 1;
  width: 19%;
}
.btn-nav_version {
  position: fixed;
  top: 10%;
  left: 0px;
  margin: 0px;
  padding: 0px;
  margin-left: 5px;
  z-index: 1;
}
.btn-nav_module {
  position: fixed;
  right: 0px;
  top: 10%;
  margin: 0px;
  padding: 0px;
  margin-right: 3px;
  width: 15%;
}

.fun_nav {
  width: 300px;
}

.pqrs_nav {
  width: 230px;
}

.clock-calendar {
  width: 100%;
}

fieldset {
  border: 3px solid #ddd !important;
}

legend {
  border: 3px solid #ddd;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
}

.fung_nav {
  height: 420px;
  width: 300px;
  overflow-y: scroll;
}

.chart-clock {
  overflow-x: scroll;
}

.container-sh {
  overflow-x: scroll;
}

.filter-list {
  overflow-y: scroll;
  height: 400px;
}

.ovy {
  overflow-y: scroll;
}

.ovx {
  overflow-x: scroll;
}

.app-pointer {
  cursor: pointer;
}

#form {
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  #form {
    max-width: 100%;
  }
}
@media (max-width: 1200px) {
  #form {
    max-width: 100%;
  }
}
