.service-button {
  width: 135px;
  height: 135px;
  background: #ccc;
  border-radius: 0.4em;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: border 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  text-wrap: pretty;
  margin: 8px;
  padding: 10px 10px 0px 10px;
}
.service-button h5 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
  color: #002a85;
}

.service-button-image-container {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  color: #2362a7; /* 2c6aa5 */
}
.service-button:hover {
  background: #e2e2e2;
  /* background: linear-gradient(90deg, rgb(93, 112, 131) 0%, rgba(0, 212, 255, 1) 100%); */
}

.service-button:active {
  transform: translate(0em, 0.2em);
}
